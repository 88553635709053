import React from 'react'
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import HubspotForm from 'react-hubspot-form'

const AireReport = ({ location }) => {
    return (
        <Layout location = {location}>
            <Helmet>
                <title>AIRe benchmarking report | Vantage Circle</title>

                <meta name="description" content="Get an in-depth report on the AIRe assessment tool and find out how it can help you transform and redesign your employee recognition program." />
                <meta property="twitter:title" content="AIRe benchmarking report | Vantage Circle" />
                        
                <meta property="twitter:description" content="Get an in-depth report on the AIRe assessment tool and find out how it can help you transform and redesign your employee recognition program." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/aire-benchmarking-report/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="AIRe benchmarking report | Vantage Circle" />

                <meta property="og:description" content="Get an in-depth report on the AIRe assessment tool and find out how it can help you transform and redesign your employee recognition program." />
                <link rel="canonical" href="https://www.vantagecircle.com/aire-benchmarking-report/"></link>
            </Helmet>
            
            <section className="bg-gray-newGrayDark mx-auto py-10 md:py-20 mb-0 md:mb-28 relative">
                <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">

                    <h1 className="homepage-heading text-center"><span className='text-orange'>Global AIR<sup>e</sup> Benchmark Report 2023</span> - Quantitatively Assessing Recognition Programs for the New World of Work</h1>
                    <p className="section-subtitle text-center">An in-depth report highlighting the findings on the effectiveness of Recognition Programs in multiple industries across the globe as per the dimensions of recognition under the AIR<sup>e</sup> framework.</p>
                    <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                        <div className="">
                            <a href="#download-now" className="vc-new-orange-btn-rounded-full">
                                Download now
                            </a>
                        </div>
                    </div>           
                </div>
            </section>

            <section className="ebook-carousel container text-center max-w-4xl md:-mt-36 mb:mb-20 px-7">
                <CarouselProvider className="relative mx-auto"
                    naturalSlideWidth={110}
                    naturalSlideHeight={80}
                    totalSlides={4}
                >
                    <Slider className="">
                        <Slide index={0}><img src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2022/10/AIRe-report-thumb-1.png" alt="Slide1"/></Slide>
                        <Slide index={1}><img src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2022/10/AIRe-report-thumb-2.png" alt="Slide2"/></Slide>
                        <Slide index={2}><img src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2022/10/AIRe-report-thumb-3.png" alt="Slide3"/></Slide>
                        <Slide index={3}><img src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2022/10/AIRe-report-thumb-4.png" alt="Slide4"/></Slide>
                    </Slider>
                    <ButtonBack className="shadow eb-carousel-btn">
                        <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                    </ButtonBack>
                    <ButtonNext className="shadow eb-carousel-btn">
                        <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                    </ButtonNext>
                    <DotGroup />
                </CarouselProvider>
            </section>
            <section id="download-now" className="container max-w-7xl py-10 md:py-20 mx-auto px-6 mt-12">
                <div className="">
                    <div className="md:flex">
                        <div className="card-1 w-full md:w-1/2 mb-10 md:mb-0 lg:mt-0">
                            <h2 className="mb-5 sm:mb-10 text-3xl text-gray-900 text-center sm:text-4xl">You will get to learn</h2>
                            <ul className="form-bullets list-inside orangebullets">
                                <li>The Global Trends and insights from the AIR<sup>e</sup> benchmarking study.</li>
                                <li>Actionable insights from five published research findings.</li>
                                <li>Enhancement insights from the AIR<sup>e</sup> benchmarking study</li>
                                <li>In-depth insights into the ways that Recognition Programs can be enhanced following the AIR<sup>e</sup> themes and dimensions.</li>
                                <li>The embedded principles of the AIR<sup>e</sup> framework using the Vantage Circle platform.</li>
                            </ul>
                        </div>
                        <div className="card-1 w-full md:w-1/2 text-gray-900 rounded-xl shadow-lg p-8 lg:p-10 md:ml-28">
                            <div className="form-section">
                                <h2 className="mt-2 mb-8 text-2xl font-semibold text-gray-900 sm:text-3xl">Fill up to download your copy now</h2>
                                <HubspotForm
                                    portalId='6153052'
                                    formId='8b83b5f5-7b3f-48c5-a32b-891d783adf62'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                                {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank">Privacy Policy</a></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AireReport
 
